import React from "react";

import { Type } from "components/type";
import { RoverLogo } from "components/logos/rover";
import { DeloitteLogo } from "components/logos/deloitte";
import { BeliefLogo } from "components/logos/belief";
import { SnapLogo } from "components/logos/snap";

/**
 * App
 */
const App: React.FC<any> = () => {
  return (
    <div className="flex flex-col justify-start items-center w-full min-h-screen bg-gray-50 relative">
      <Type classes="fixed w-auto max-h-screen left-50 translate transform z-0" />

      <div className="flex flex-col justify-center items-center z-40" style={{ minHeight: "calc(100vh - 150px)" }}>
        <div
          className="relative mb-20 bg-white shadow-2xl w-24 h-24 smooth-shadow justify-center items-center rounded-full sticky"
          style={{ top: 20 }}
        >
          <img src="/assets/me.png" className="rounded-full object-fill select-none pointer-events-none" />
        </div>
      </div>

      <div className="relative transform -translate-y-20 w-full sm:max-w-screen-lg mx-auto px-5 sm:px-0 z-50">
        <a
          href="https://twitter.com/roverdotcom"
          target="_new"
          style={{
            backgroundImage: "url('/assets/backgrounds/rover-bg.png')",
            backgroundSize: "cover",
            backgroundPosition: "center"
          }}
          className="rounded w-full bg-gray-900 flex flex-row mt-10 justify-center items-center shadow-2xl mx-6 py-16 max-w-lg mx-auto transform translate md:hover:-translate-y-2 duration-150 ease-in-out"
        >
          <RoverLogo />
        </a>

        <a
          href="https://twitter.com/deloittedigital"
          target="_new"
          style={{
            backgroundImage: "url('/assets/backgrounds/deloitte-bg.png')",
            backgroundSize: "cover",
            backgroundPosition: "center"
          }}
          className="rounded w-full bg-gray-900 flex flex-row mt-10 justify-center items-center shadow-2xl mx-6 py-16 max-w-lg mx-auto transform translate md:hover:-translate-y-2 duration-150 ease-in-out"
        >
          <DeloitteLogo />
        </a>

        <a
          href="https://twitter.com/beliefagency"
          target="_new"
          style={{
            backgroundImage: "url('/assets/backgrounds/belief-bg.png')",
            backgroundSize: "cover",
            backgroundPosition: "center"
          }}
          className="rounded w-full bg-gray-900 flex flex-row mt-10 justify-center items-center shadow-2xl mx-6 py-16 max-w-lg mx-auto transform translate md:hover:-translate-y-2 duration-150 ease-in-out"
        >
          <BeliefLogo />
        </a>

        <a
          href="https://twitter.com/SnapRaise"
          target="_new"
          style={{
            backgroundImage: "url('/assets/backgrounds/snap-bg.png')",
            backgroundSize: "cover",
            backgroundPosition: "center"
          }}
          className="rounded w-full bg-gray-900 flex flex-row mt-10 justify-center items-center shadow-2xl mx-6 py-16 max-w-lg mx-auto transform translate md:hover:-translate-y-2 duration-150 ease-in-out"
        >
          <SnapLogo />
        </a>
      </div>
    </div>
  );
};

export { App };
